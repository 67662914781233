import styled from "styled-components";
import { fontSizes, fontColors, colors } from "./variables";
import { device } from "../utils/media";

export const MainBody = styled.div`
  background: ${colors.mainColor};
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
`;
export const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  width: 95%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.mobile} {
    width: 85%;
  }
`;
export const FlexRow = styled.div`
  display: flex;
  z-index: 1;
  p {
    color: ${fontColors.mainColor};
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
export const Column = styled.div`
  width: ${props => props.width};
`;

export const ColumnText = styled.div`
  width: 60%;
  padding-right: 50px;
  position: relative;
  @media ${device.mobile} {
    width: 100%;
    font-size: 1em;
    padding-right: 0;
  }
`;
export const ColumnContacts = styled.div`
  width: 40%;
  @media ${device.mobile} {
    width: 100%;
  }
`;
export const Heading1 = styled.h1`
  font-size: ${fontSizes.heading1};
  color: ${fontColors.mainColor};
  max-width: ${props => props.maxWidth};
  margin: 0;
  @media ${device.mobile} {
    font-size: 3em;
  }
`;
