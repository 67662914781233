export const colors = {
  mainColor: "#62ddd1",
  circle: "#74e6db"
};
export const fontSizes = {
  heading1: "4em",
  heading2: "2em",
  heading3: "1.5em"
};
export const fontColors = {
  mainColor: "#151e5f",
  labelColor: "#51546f"
};
