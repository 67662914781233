import styled from "styled-components";
import { fontColors } from "../../utils/variables";

export const FooterContainer = styled.div`
  color: ${fontColors.mainColor};
  padding-bottom: 50px;
  a {
    color: ${fontColors.mainColor};
    text-decoration: none;
  }
`;
