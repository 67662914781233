import styled from "styled-components";
import { fontColors, colors } from "../../utils/variables";
import { device } from "../../utils/media";
export const AppList = styled.ul`
  padding: 0;
  margin: 0;
  max-width: 300px;
  padding-top: 20px;

  li {
    list-style: none;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const EmailList = styled.ul`
  padding: 0;
  margin: 0;
  padding-top: 20px;
  li {
    a {
      color: ${fontColors.mainColor};
      text-decoration: none;
      display: block;
      padding-top: 10px;
      font-weight: 500;
    }
    margin-bottom: 30px;
    list-style: none;
    label {
      display: block;
      color: ${fontColors.labelColor};
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 500;
    }
    img {
      height: 15px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
`;

export const Circle = styled.div`
  width: 450px;
  height: 450px;
  background: ${colors.circle};
  border-radius: 50%;
  right: 50px;
  position: absolute;
  top: 20px;
  z-index: -1;
  @media ${device.mobile} {
    width: 250px;
    height: 250px;
  }
`;
