import React from "react";
import { HeaderContainer, Logo, Triangle } from "./styles";
import Linkedin from "../../assets/icons/linkedin.svg";
import GitHub from "../../assets/icons/github.svg";
import LogoImg from "../../assets/icons/logo.png";
const Header = () => {
  return (
    <HeaderContainer>
      <Logo>
        <a href="https://virtualireklama.lt" title="Virtuali Reklama">
          <img src={LogoImg} alt="Virtualireklama.lt logo" />
        </a>
      </Logo>
      <Triangle />
    </HeaderContainer>
  );
};
export default Header;
