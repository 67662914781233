import React from "react";
import { MainBody, Container } from "./utils/general";
import Header from "./components/header";
import Footer from "./components/footer";
import MainContent from "./components/mainContent";

function App() {
  return (
    <MainBody>
      <Container>
        <Header />
        <MainContent />
        <Footer />
      </Container>
    </MainBody>
  );
}

export default App;
