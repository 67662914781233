import styled from "styled-components";
import { colors } from "../../utils/variables";
import { device } from "../../utils/media";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: center;
  z-index: 1;
`;
export const Logo = styled.div`
  height: 50px;
  color: #fff;
  font-size: 3em;
  a {
    color: #fff;
    text-decoration: none;
  }
  img {
    width: 200px;
  }
`;
export const Socials = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    display: inline-block;
    a {
      color: #fff;
      text-decoration: none;
      img {
        width: 20px;
      }
      :hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 300px solid ${colors.circle};
  transform: rotate(90deg);
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  @media ${device.mobile} {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid ${colors.circle};
  }
`;
