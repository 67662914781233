import React from "react";
import {
  FlexRow,
  ColumnText,
  ColumnContacts,
  Heading1
} from "../../utils/general";
import { APP_LIST } from "../../mocs/APP_LIST";
import { AppList, EmailList, Circle } from "./styles";
import PhoneNr from "../../assets/phone-nr.png";

const mainContent = () => {
  return (
    <FlexRow>
      <ColumnText>
        <Heading1>We can help you to build your next great project.</Heading1>
        <p>Just as we helped others, let's talk.</p>
        {/* <AppList>
          {APP_LIST.map(app => (
            <a href={app.url}>
              <li
                key={app.id}
                style={{ backgroundImage: `url(${app.imgUrl})` }}
              ></li>
            </a>
          ))}
        </AppList> */}
        <Circle />
      </ColumnText>
      <ColumnContacts>
        <EmailList>
          <li>
            <label>MAIL US</label>
            <a href="mailto:info@virtualireklama.lt">info@virtualireklama.lt</a>
          </li>
        </EmailList>
      </ColumnContacts>
    </FlexRow>
  );
};
export default mainContent;
