import React from "react";
import { FooterContainer } from "./styles";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = () => {
  return (
    <FooterContainer>
      © Virtualireklama.lt {currentYear}
    </FooterContainer>
  );
};
export default Footer;
