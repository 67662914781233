const size = {
  small: "576px",
  medium: "768px",
  large: "992px",
  desktop: "1120px"
};

export const device = {
  mobile: `(max-width: ${size.small})`,
  tabletPortrait: `(max-width: ${size.medium})`,
  tabletLandscape: `(max-width: ${size.large})`,
  desktop: `(max-width: ${size.desktop})`
};
